import React, { useState, useEffect } from "react"
import _ from "underscore"
import { Redirect, useHistory } from "react-router-dom"
import { Card, notification } from "antd"
import { FormattedMessage } from "react-intl"
import { useApply } from "../hooks/useApply"
import { useTheme } from "../hooks/useTheme"
import ApplyForm from "../components/forms/ApplyForm"
import Loader from "../components/core/Loader"
import { createLead, prepareLead, pingAndWait } from "../services/lead.service"
import { useAuth } from "../hooks/useAuth"
import { Spinner } from "../components/core/Spinner"
import BasicHeader from "../components/layout/BasicHeader"
import PoweredBy from "../components/core/PoweredBy"

// import CitizensPrequal from "../components/lenders/CitizensPrequal";

function ApplyPage() {
  console.log("this is appply")
  const { merchant, product } = useApply()
  const { poweredByLogo } = useTheme()
  console.log("merchant", merchant)
  const [lead, setLead] = useState(null)
  const [loading, setLoading] = useState(false)
  const { updateAuth } = useAuth()
  const history = useHistory()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [autoTooltip, setAutoTooltip] = useState(false)

  useEffect(() => {
    if (process.env.REACT_APP_FE === "bolster") {
      setAutoTooltip(true)
    } else {
      setAutoTooltip(false)
    }
  }, [merchant])

  /**
   * If not merchant redirect to root page
   */
  if (!merchant) {
    return (
      <Redirect
        to={{
          pathname: `/`,
        }}
      />
    )
  }

  /**
   * If we have a lead - go to that lead
   */
  if (lead) {
    return (
      <Redirect
        to={{
          pathname: `/apps/${lead.id}`,
          state: { lead },
        }}
      />
    )
  }

  if (!merchant) {
    return <Spinner />
  }

  async function onSubmit(values, actions) {
    actions.setSubmitting(true)
    setLoading(true)
    setIsSubmitted(true)

    let leadObj = await prepareLead(values)

    const seonSessionPayload = sessionStorage.getItem("seonSessionPayload")
    if (seonSessionPayload) {
      leadObj.device_profiles = leadObj.device_profiles || []
      leadObj.device_profiles.push({
        provider: "seon",
        session_id: seonSessionPayload,
      })
    }

    console.debug("Lead object", leadObj)

    if (product) {
      leadObj.product = { url: product.url }
    }

    const resp = await createLead(leadObj)
    if (resp.success) {
      await updateAuth(resp.data.token)
      const pingd = await pingAndWait(resp.data.id)
      if (pingd.success) {
        setLoading(false)
        actions.setSubmitting(false)
        console.debug("Ping success data:", pingd.data)
        setLead(pingd.lead.data)
        history.push(`/apps/${pingd.lead.data.id}`)
      }
    } else {
      notification.error({
        message: "Error",
        description: (
          <FormattedMessage
            defaultMessage="There was an error processing your application"
            id="applyPage.error"
          />
        ),
        duration: 5,
      })
      _.keys(resp.error.data).forEach((k) => {
        if (_.isObject(resp.error.data[k])) {
          _.keys(resp.error.data[k]).forEach((kk) => {
            actions.setFieldError(`${k}.${kk}`, resp.error.data[k][kk][0])
          })
        } else {
          actions.setFieldError(k, resp.error.data[k][0])
        }
      })
      setIsSubmitted(false)
      setLoading(false)
    }
  }

  const fields = _.omit(merchant.ping_fields, function (value) {
    if (_.isObject(value)) return _.keys(value).length === 0
    if (_.isBoolean(value)) return !value
    return false
  })

  function setDefaults(fields) {
    Object.entries(fields).forEach((entry) => {
      const [k, v] = entry
      if (_.isObject(v)) setDefaults(v)
      if (_.isBoolean(v)) fields[k] = ""
    })
  }

  setDefaults(fields)
  fields.merchant = { url: merchant.url }
  fields.amount = merchant.code === "citizens" ? 2000 : 1000
  fields.purpose = "large_purchases"
  fields.agree = false
  const additionalCompliance = null
  // fields['citizensAgree'] = false;

  return (
    <Card>
      <BasicHeader
        text={
          <FormattedMessage
            id="ApplyPage.header"
            defaultMessage="Complete your application to get offers instantly!"
          />
        }
      />
      <Loader
        visible={loading}
        title={
          <FormattedMessage
            id="ApplyPage.loaderTitle"
            default="Searching For Offers"
          />
        }
        body={
          <FormattedMessage
            id={
              merchant.code === "fortiva"
                ? "ApplyPage.loaderBody.fortiva"
                : "ApplyPage.loaderBody"
            }
            defaultMessage="Please while while we search our lender network"
          />
        }
      />
      <ApplyForm
        values={fields}
        submitText={
          <FormattedMessage
            defaultMessage="Search for Offers"
            id={
              merchant.code === "trek"
                ? "buttons.apply.trek"
                : "ApplyForm.submit"
            }
          />
        }
        onSubmit={onSubmit}
        isSubmitted={isSubmitted}
        additionalCompliance={additionalCompliance}
        merchantCode={merchant.code}
        autoTooltip={autoTooltip}
      />
      {/* {renderCompliance()} */}
      {poweredByLogo && <PoweredBy />}
    </Card>
  )
}

export default ApplyPage
