import { Row, Col, Button, Card } from "antd"

const ProductCard = ({ item, selectProduct }) => {
  return (
    <Card
      bodyStyle={{
        paddingBottom: 0,
        paddingRight: 10,
        paddingLeft: 10,
      }}
      style={{
        marginTop: 10,
        marginBottom: 10,
        paddingBottom: 20,
        cursor: "pointer",
        width: "100%",
        fontSize: 12,
      }}
    >
      <Row align="middle">
        <Col span={18}>
          <Row justify="space-between">
            <Col>
              <strong style={{ fontWeight: 600 }}>
                {item?.description || ""}
              </strong>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }} justify="space-between">
            <Col>
              <strong style={{ fontWeight: 600 }}>
                {item?.brand || ""} {item?.sku ? `| ${item?.sku}` : ""}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col span={6} style={{ paddingLeft: 15 }}>
          <Row justify="space-between">
            <Col>
              <strong style={{ fontWeight: 600 }}>
                ${Number(item?.price || 0).toLocaleString()}
              </strong>
            </Col>
            {selectProduct && (
              <Col>
                <Button
                  type="primary"
                  style={{
                    marginTop: 5,
                    borderRadius: 10,
                    padding: "0px 10px",
                  }}
                  onClick={() => selectProduct(item)}
                >
                  Select
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default ProductCard
